import { Container, Navbar, Nav } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import contactIcon from "../../Assets/Kontakt-icon.svg";
import { useLocation, Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AccessibilityContext } from "../../context/accessibilityContext";
import { navRoutesData } from "../routesDataForNav";
import "./Navbar.scss";
export default function NavbarComp() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const { displaySize } = useContext(AccessibilityContext);
  const location = useLocation().pathname;

  const isActivePath = (href) => {
    return href === location;
  };
  let shouldSkip = true;
  useEffect(() => {
    const skipToContent = (event) => {
      if (event.key === "Tab" && shouldSkip) {
        console.log("hi");
        const contentElement = document.getElementById("page-content");
        if (contentElement) {
          contentElement.focus();
          event.preventDefault();
          shouldSkip = false;
        }
      }
    };

    document.addEventListener("keydown", skipToContent);

    return () => {
      document.removeEventListener("keydown", skipToContent);
    };
  }, []);
  function toggleNavbarOpen() {
    setIsNavbarOpen(!isNavbarOpen);
  }
  useEffect(() => {
    if (isNavbarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isNavbarOpen]);
  return (
    <>
      <Navbar expand="md">
        <Container>
          <Navbar.Brand href="/" tabIndex={0}>
            <img alt="Bestattungen Lichtblick Logo" src={logo} />
          </Navbar.Brand>
          <nav className="navBtns">
            {displaySize.isDesktop ? (
              <>
                <Link
                  className={isActivePath("/") ? "active" : ""}
                  to="/"
                  title="Home"
                >
                  Willkommen
                </Link>
                <Link
                  className={isActivePath("/Über-uns") ? "active" : ""}
                  to="/Über-uns"
                  title="Über uns"
                >
                  Über uns
                </Link>
                {/*<Link
                  className={isActivePath("/kontakt") ? "active" : ""}
                  to="/kontakt"
                  title="kontakt"
                >
                  Kontakt
                </Link> */}
              </>
            ) : (
              <></>
            )}
            <a href={`tel:${"+492349580004"}`} className="call-nav-link">
              <img
                alt="contact Icon"
                src={contactIcon}
                className="contact-Icon desk"
              />
            </a>
            {displaySize.isDesktop ? (
              <></>
            ) : (
              <Navbar.Toggle
                className={`hamburgerIcon ${isNavbarOpen ? "open" : ""}`}
                aria-controls="navbarDropdown"
                onClick={toggleNavbarOpen}
                aria-expanded={isNavbarOpen}
              >
                <span></span>
                <span></span>
                <span></span>
              </Navbar.Toggle>
            )}
          </nav>
        </Container>
      </Navbar>
      {displaySize.isDesktop ? (
        <></>
      ) : (
        <AnimatePresence>
          {!displaySize.isDesktop && (
            <motion.div
              initial={{ opacity: 0, y: "-111%" }}
              animate={{ opacity: 0.95, y: isNavbarOpen ? 0 : "-111%" }}
              exit={{ opacity: 0, y: "-111%" }}
              transition={{ duration: 0.3 }}
              className="collapse-bg"
              style={{
                zIndex: 1,
                position: "fixed",
              }}
            >
              <Navbar.Collapse id="navbarDropdown">
                <nav className="navbarDropdownNav">
                  <Link
                    onClick={toggleNavbarOpen}
                    className={isActivePath("/") ? "active" : ""}
                    title="Home"
                    to="/"
                  >
                    Willkommen
                  </Link>
                  <Link
                    onClick={toggleNavbarOpen}
                    className={isActivePath("/Über-uns") ? "active" : ""}
                    to="/Über-uns"
                    title="Über uns"
                  >
                    Über uns
                  </Link>
                  {/*}
                  <Link
                    onClick={toggleNavbarOpen}
                    className={isActivePath("/kontakt") ? "active" : ""}
                    to="/kontakt"
                    title="kontakt"
                  >
                    Kontakt
                  </Link> */}
                  {navRoutesData.map((el, ind) => {
                    return (
                      <Link
                        onClick={toggleNavbarOpen}
                        key={ind}
                        className={isActivePath(el.path) ? "active" : ""}
                        to={el.path}
                        title={el.pathName}
                      >
                        {el.pathName}
                      </Link>
                    );
                  })}
                </nav>
              </Navbar.Collapse>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}
