import "./PriceModel.scss";
import DetailsBtn from "../Btn/DetailsBtn";
export default function PriceModel({
  heading,
  price,
  services,
  isStar = false,
  link,
  hasBtn = true,
  target,
  btnName,
}) {
  let priceStr = `${price} €`;
  return (
    <div className={"priceModel-c" + (hasBtn ? "" : " hasBtn")}>
      <p className="heading">{heading}</p>
      <p className="price">
        {priceStr}
        {isStar ? <span>*</span> : <></>}
      </p>
      <p className="services">{services}</p>
      {hasBtn ? <DetailsBtn link={link} target={target} btnName={btnName}/> : <></>}
    </div>
  );
}
