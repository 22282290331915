export const contentData = {
  h3_1: "Komplettangebot Erdbestattung",
  p1: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
  diam nonumy eirmod tempor invidunt ut labore et dolore magna
  aliquyam erat, sed diam voluptua. At vero eos et accusam et
  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
  ipsum dolor sit amet.`,
  h3_2: "Komplettangebot Erdbestattung",
  h3_3: "Inhalt des Angebotes",
  ul1: [
    `Kiefernsarg für eine Erdbestattung`,
    `Decke und Kissen`,
    `Sterbehemd , Verwendung von Privatkleidung ist möglich`,
    `Desinfektion`,
    `Einbetten / Einkleiden`,
    `Individuelle Beratung`,
    `Umfassende Beratung in allen Bestattungsfragen`,
    `Fahrer und Beifahrer für die Überführung`,
    `Überführung vom Sterbeort zum Friedhof (eventuelle Mehrkosten, unter  Bestattung NRW erhalten Sie genauere Informationen)`,
    `Benachrichtigung der Krankenkasse`,
    `Abmeldung der Rentenansprüche`,
    `Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen`,
    `Beurkundung beim zuständigen Standesamt`,
  ],
  ul2: [
    `Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen`,
    `Beurkundung beim zuständigen Standesamt`,
    `Einholung und Überprüfung der Todesbescheinigung`,
    `Besorgen der amtsärztlichen Leichenschau`,
    `Aufnahme von Traueranzeigen`,
    `Absprache aller notwendigen Termine`,
    `Benachrichtigung der zuständigen Friedhofsverwaltung`,
    `Koordination der Trauerfeier mit der Kirchengemeinde`,
    `Urnenträger zur Beisetzung`,
    `Trägerhandschuhe`,
    `Auf Wunsch Fotos von der Trauerfeier`,
    `Betreuung und Dienste auf dem Friedhof`,
  ],
};
