import landingImage from "../../Assets/Feuerbestattungen_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Komplettangebote.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { packagesData } from "./content-Komplettangebote";
import PriceModel from "../../Components/PriceModel/PriceModel";
import { Helmet } from 'react-helmet-async';

export default function Komplettangebote() {
  return (
    <div className="komplettangebote-p page">
        <Helmet>
            <title>Unserer Komplettangebote | Bestattungen Lichtblick</title>
            <meta name='description' content='Feuerbestattung, Erdbestattung oder anonymer Beisetzung. Sichern Sie sich jetzt Ihr Angebot!' />
        </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Drei brennende Kerzen auf dunklem Hintergrund."}
        text={`Unsere Komplettangebote für die Feuerbestattung, anonyme Beisetzung oder eine Erdbestattung`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
                <p className="w-100">Hier finden Sie eine Auflistung unserer Komplettangebote.<br />
                    Um weitere und genauere Informationen zu den Komplettangeboten zu erhalten, klicken Sie auf das gewünschte Angebot.<br /><br />
                    Bei dem Komplettangebot "Anonyme Beisetzung" sind die Friedhofs- und Krematoriumskosten bereits enthalten.<br />
                    <span className="text-primary text-bold">Bei diesem Angebot entstehen für ganz NRW keine weiteren Kosten.</span></p>

          {packagesData.map((el, ind) => {
            return (
              <div className="package-type" key={ind}>
                <h2 className="t2">
                  {el.packageType}
                  {el.ven && <br />}
                  {el.ven && el.ven}
                </h2>
                <div className="package">
                  {el.packages.map((el, indIn) => {
                    return (
                      <PriceModel
                        key={indIn}
                        heading={el.packageName}
                        price={el.price}
                        services={el.services}
                        link={el.link}
                        isStar={el.isStar}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}

            <article className="warnPara">
                <p>
                    WICHTIGE INFORMATION:
                    <br />
                    Bitte beachten Sie, dass in den oben aufgeführten Preisen keine Fremdleistungen enthalten sind. Zu den Fremdleistungen gehören z.B. Grab- und Bestattungsgebühren, Krematoriumsgebühren, Traueranzeigen, Sterbeurkunden, Todesbescheinigung etc. Alle Preise verstehen sich inklusive der gesetzlichen MwSt.
                </p>
            </article>

        </div>
      </div>
    </div>
  );
}
