import landingImage from "../../Assets/Home_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Impressum.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Impressum() {
    return (
        <div className="impressum-p">
            <Helmet>
                <title>Impressum | Bestattungen Lichtblick</title>
                <meta name='description' content='Bestattungen Lichtblick | Frauenlobstr. 49 | 44805 Bochum | Telefon: 0234- 958 00 04' />
            </Helmet>
            <LandingImageHeader
                img={landingImage}
                alt={"Sonnenstrahlen durchdringen den Herbstwald, während sie zwischen den Bäumen hindurchscheinen."}
                text={`Impressum`}
            />
            <div className="flex-div">
                <SideNav />
                <div tabIndex={0} id="page-content" className="page-content">
                    <article>
                        <p>
                            <strong>Firmenanschrift:</strong><br />
                            Bestattungen Lichtblick<br />
                            Frauenlobstr. 49<br />
                            44805 Bochum<br />
                            Inhaber: Paul Holz<br />
                            Email: info@bestattungen-lichtblick.de<br />
                            Telefon: 0234-9580004<br />
                            Telefax: 0234-9580005<br />
                            USt.-ID- Nummer: 252 898 924<br />
                        </p>
                        <p>
                            <strong>Haftungsausschluss</strong><br />
                            Bestattungen Lichtblick betont nochmals ausdrücklich, dass es keinen Einfluss auf die Inhalte und Gestaltung der gelinkten Seiten hat. Deshalb distanzieren wir uns ausdrücklich von allen Inhalten aller gelinkten Seiten. Diese Erklärung gilt für alle geschalteten Links (nach dem Urteil vom 12.05.1998 des Landgerichts Hamburg).
                        </p>
                        <p>
                            <strong>Urheberrechtshinweise</strong><br />
                            Alle auf dieser Website veröffentlichten Beiträge und Abbildungen sind urheberrechtlich geschützt. Jede vom Urheberrechtsgesetz nicht zugelassene Verwertung bedarf vorheriger schriftlicher Zustimmung von Bestattungen Lichtblick. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Fotokopien und Downloads von Web-Seiten dürfen nur für den persönlichen, privaten und nicht kommerziellen Gebrauch hergestellt werden.
                        </p>
                    </article>
                </div>
            </div>
        </div>
    );
}
