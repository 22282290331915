import landingImage from "../../Assets/Trauerfall_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Trauerfall.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';
import { trauerfallData } from "./content-Trauerfall";

export default function Trauerfall() {
  return (
    <div className="trauerfall-p">
      <Helmet>
        <title>Trauerfall | Bestattungen Lichtblick</title>
        <meta name='description' content='Welche Dokumente sind wichtig für die Bestattung? Hier finden Sie eine Übesicht. Jetzt informieren!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Drei weiße Calla Lilien auf dunklem Hintergrund. Harmonisches Arrangement mit subtilen Kontrasten."}
        text={`Welche Dokumente sind wichtig für die Bestattung?`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <p><strong>Folgende Papiere und Unterlagen werden zur Ausführung der
              notwendigen Formalitäten im Zusammenhang mit der Bestattung
              benötigt:</strong></p>
            <p className="p2">
              Familienstand ledig:<span> Geburtsurkunde </span>
              <br />
              Familienstand verheiratet:<span> Heiratsurkunde </span>
              <br />
              Familienstand geschieden:
              <span> Heiratsurkunde, Scheidungspapiere</span>
              <br />
              Familienstand verwitwet:
              <span> Heiratsurkunde, Sterbeurkunde des Ehepartners</span>
            </p>
          </article>
          <article>
            <h3>{trauerfallData.h3_1}</h3>
            <p>{trauerfallData.p3}</p>
            <p className="bold">{trauerfallData.p4}</p>
          </article>

          <div className="line"></div>
          <h3>{trauerfallData.h3_2}</h3>
          <p>{trauerfallData.p5}</p>
          <p className="bold">{trauerfallData.p6}</p>
          <div className="line"></div>
          <h3>{trauerfallData.h3_3}</h3>
          <p>{trauerfallData.p7}</p>
        </div>
      </div>
    </div>
  );
}
