export const mainHeadline = 'Unsere Leistungen'

export const packagesData = [
    {
        packageType: "Dieser Preis gilt für ganz NRW (inkl. Krematoriumskosten)",
        packages: [
            {
                packageName: "Unser Komplettpreis für ganz NRW",
                price: "1.200",
                services: "zzgl. Bestattungskosten",
                link: "/Komplettangebote/Feuerbestattungen",
                isStar: false,
            },
        ],
    },
];
