import React, { useState } from "react";
import ImageAccordion from "../ImageAccordion/ImageAccordion";
import "./BestattungenType.scss";

export default function BestattungenType({ typeTitle, data }) {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <section>
      <h3 className="title1">{typeTitle}</h3>
      <div>
        {data.map((el, index) => (
          <ImageAccordion
            key={index}
            title={el.title}
            subline={el.subline}
            img={el.img}
            alt={el.alt}
            content={el.content}
            page={el.page}
            isOpen={openAccordionIndex === index}
            onClick={() => handleAccordionClick(index)}
          />
        ))}
      </div>
    </section>
  );
}
