import "./LandingImageHeader.scss";
import { useContext } from "react";
import { AccessibilityContext } from "../../context/accessibilityContext";
export default function LandingImageHeader({
  img,
  text = `Bestattungen Lichtblick - VIELFÄLTIGE Komplettangebote für Ihren
Trauerfall in ganz NRW`,
    alt = "Bestattungen Lichtblick"
}) {
  // eslint-disable-next-line
  const { displaySize } = useContext(AccessibilityContext);
  return (
    <div className="landingImageHeader-c">
      <div className="full-width-image">
        <img
          src={img}
          loading="lazy"
          alt={alt}
          className="fixed-image"
        />
      </div>
      <div className="container">
        <div className="contactInfo">
          <a href={`tel:${"+492349580004"}`}>
            <p>
              Tag & Nacht für Sie erreichbar: <br />
              Telefon 0234 - 958 00 04
            </p>
          </a>
        </div>
        <div className="line"></div>
        <div className="extraInfo">
          <h1>{text}</h1>
          {/*
           {displaySize.width < 450 ? (
            <h1>
              VIELFÄLTIGE Komplettangebote <br />
              für Ihren Trauerfall in ganz NRW
            </h1>
          ) : (
            <h1>{text}</h1>
          )} */}
        </div>
      </div>
    </div>
  );
}
