import landingImage from "../../Assets/Finanzierung_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Finanzierung.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Finanzierung() {
  return (
    <div className="finanzierung-p">
      <Helmet>
        <title>Finanzierungsmöglichkeiten | Bestattungen Lichtblick</title>
        <meta name='description' content='Wir bieten Finanzierungsmöglichkeiten mit Ratenzahlungen von bis zu 48 Monaten an. Jetzt informieren!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Tropfen Wasser auf Gras, natürliche Frische nach dem Regen."}
        text={`Finanzierungsmöglichkeiten in der Not beim Trauerfall`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <p>Nicht jeder Mensch hat finanziell für seine Bestattung vorgesorgt oder vorsorgen können. Schon gar nicht, wenn der Tod ganz plötzlich kommt oder jüngere Menschen betrifft.<br />
              Für Hinterbliebene bedeutet dies eine zusätzliche Belastung.<br />
              Anstelle kurzfristiger und teurer Dispokredite empfehlen wir die Finanzierung über die „abcFinance“.<br />
              Die „abcFinance“ ist ein spezialisierter Anbieter und bietet Hinterbliebenen günstige und auf ihre finanziellen Möglichkeiten zugeschnittene Angebote mit Ratenzahlungen bis zu 48 Monaten an.
            </p>
          </article>
          <table>
            <tr>
              <th>Laufzeit</th>
              <th>Gebühr einmalig auf Gesamtbetrag</th>
            </tr>
            <tr>
              <td>bis zu 3 Monaten</td>
              <td>0% (ohne weitere Kosten)</td>
            </tr>
            <tr>
              <td>bis zu 6 Monaten</td>
              <td>1,5 %</td>
            </tr>
            <tr>
              <td>bis zu 9 Monaten</td>
              <td>2,5 %</td>
            </tr>
            <tr>
              <td>bis zu 12 Monaten</td>
              <td>3 %</td>
            </tr>
            <tr>
              <td>längere Laufzeit</td>
              <td>individuelles Angebot</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
