import landingImage from "../../../Assets/Anonyme_Bestattung_landing_img.jpg";
import pdfFile from "../../../Assets/downloads/Anonyme+Bestattung+Auftrag+22.pdf";
import LandingImageHeader from "../../../Components/LandingImageHeader/LandingImageHeader";
import "./Anonyme-Bestattung.scss";
import SideNav from "../../../Components/SideNav/SideNav";
import PriceModel from "../../../Components/PriceModel/PriceModel";
import { Helmet } from 'react-helmet-async';
import { contentData } from "./content-Anonyme-Bestattung";

export default function AnonymeBestattung() {
  return (
    <div className="flex-column anonyme-bestattung-p page">
      <Helmet>
        <title>Anonymer Bestattung | Bestattungen Lichtblick</title>
        <meta name='description' content='Feuerbestattung mit anonymer Beisetzung inkl. Beisetzungskosten und Krematoriumsgebühren. Jetzt Angebot anfordern!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Dunkler Nachthimmel mit funkelnden Sternen."}
        text={`Feuerbestattung mit anonymer Beisetzung`}
      />
      <div className="flex-div flex-row">
        <SideNav />
        <div
          tabIndex={0}
          id="page-content"
          className="page-content flex-column"
        >

          <div className="price-block ">
            <article className="margin-div flex-column">
              <h3>{contentData.h3_2}</h3>
              <div className="content flex-column">
                <PriceModel
                  heading="Feuerbestattung mit anonymer Beisetzung"
                  price="1.150"
                  services="inkl. Beisetzungskosten und Krematoriumsgebühren"
                  hasBtn={true}
                  link={pdfFile}
                  btnName="Download Auftrag"
                  target="_blank"
                />
                <p className="p1">{contentData.p1}</p>
              </div>
            </article>
          </div>
          <div className="details-ul flex-column">
            <h3>{contentData.h3_3}</h3>
            <div className="bullet-point ">
              <ul>
                <li>Beisetzungskosten sind im Paket enthalten</li>
                <li>Kiefernsarg inkl. Bespannung</li>
                <li>Decke und Kissen</li>
                <li>Sterbehemd , Verwendung von Privatkleidung ist möglich</li>
                <li>Desinfektion</li>
                <li>Einbetten / Einkleiden</li>
                <li>Umfassende Beratung in allen Bestattungsfragen</li>
                <li>Fahrer und Beifahrer für die Überführung</li>
                <li>Überführung vom Sterbeort zum Krematorium</li>
                <li>Benachrichtigung der Krankenkasse</li>
                <li>Abmeldung der Rentenansprüche</li>
              </ul>
              <ul>
                <li>Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen</li>
                <li>Beurkundung beim zuständigen Standesamt</li>
                <li>Einholung und Überprüfung der Todesbescheinigung</li>
                <li>Besorgen der amtsärztlichen Leichenschau</li>
                <li>Einholung aller benötigten Unterlagen für die Feuerbestattung</li>
                <li>Aufnahme von Traueranzeigen</li>
                <li>Absprache aller notwendigen Termine</li>
                <li>Vorbereitung und Organisation der Beisetzung</li>
                <li>Krematoriumsgebühren</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
