export const mainHeadline = 'Bestattungen Lichtblick – Bestattungen aller Art zu günstigen Konditionen in ganz Nordrhein-Westfalen.';

export const packagesData = [
  {
    packageType: "Komplettangebote mit Trauerfeier",
    packages: [
      {
        packageName: "Feuerbestattung mit Trauerfeier",
        price: "999",
        services: "zzgl. städt. Kosten",
        link: "/Komplettangebote/Feuerbestattungen",
        isStar: false,
      },
      {
        packageName: "Erdbestattung mit Trauerfeier & Sarg",
        price: "1.800",
        services: "zzgl. städt. Kosten",
        link: "/Komplettangebote/Erdbestattungen",
        isStar: false,
      },
    ],
  },
  {
    packageType: "Komplettangebote für ganz NRW*",
    packages: [
      {
        packageName: "Feuerbestattung mit anonymer Beisetzung",
        price: "1.150",
        services: "inkl. Beisetzungskosten und Krematoriumsgebühren",
        link: "/Komplettangebote/Anonyme-Bestattung",
        isStar: false,
      },
        {
        packageName: "Seebestattung in der Nordsee",
        price: "1.150",
        services: "inkl. Krematorium & Beisetzung",
        link: "/Seebestattungen",
        isStar: false,
      },
      {
        packageName: "Seebestattung in der Ostsee",
        price: "1.350",
        services: "inkl. Krematorium & Beisetzung",
        link: "/Seebestattungen",
        isStar: false,
      },
      {
        packageName: "Seebestattung in der Nordsee mit Angehörigen",
        price: "1.800",
        services: "inkl. Krematorium & Beisetzung",
        link: "/Seebestattungen",
        isStar: false,
      },
    ],
  },
  {
    packageType: "Komplettangebote für ganz NRW für die Waldbestattung*",
    packages: [
      {
        packageName: "Komplettpreis für ganz NRW inkl. Krematorium",
        price: "1.200",
        services: "zzgl. Bestattungskosten",
        link: "/Waldbestattungen",
        isStar: false,
      },
    ],
  },
];

export const info_1 = `*Für die anonyme Bestattung, die Seebestattung sowie bei der Waldbestattung entstehen in ganz NRW keine weiteren Überführungskosten.<br>**Andere Bundesländer gerne auf Anfrage!`;

export const info_2_Title = ``;
export const info_2 = `Die Bestattungsangebote gelten für den Kreis Bochum und Umgebung, für andere Teile von Nordrheinwestfalen kommen Überführungskosten hinzu, die Sie unter dem Punkt Bestattung NRW entnehmen können. Zudem bieten wir Ihnen die Möglichkeit einer Finanzierung bis zu 12 Monaten an.`;

export const info_3 = {
  i1_1: `Die Komplettangebote sind jederzeit erweiterbar und können nach Ihren Wünschen gestaltet werden. In einem persönlichen Gespräch können wir am besten besprechen welche Möglichkeiten für Sie in Frage kommen.`,
  i1_2: `Wir führen auch bundesweit & international Bestattungen aus, einzelne Preise erfragen Sie bitte telefonisch oder per Email.`,
  i2_1: `Wir übernehmen die Überführung von Verstorbenen ins Ausland oder Heimschaffung von im Ausland Verstorbenen zu preiswerten Konditionen. `,
};
