import landingImage from "../../Assets/Leistungen_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Leistungen.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Leistungen() {
  return (
    <div className="trauerfall-p">
      <Helmet>
        <title>Unsere Leistungen | Bestattungen Lichtblick</title>
        <meta name='description' content='Beratung und die konplette Organisation im Todesfall gehören zur unseren Leistungen. Jetzt informieren!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Vergissmeinnicht-Blumen auf einem Holztisch."}
        text={`Unsere Leistungen rund um Ihren Trauerfall`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <h2>Unmittelbarer Dienst für Verstorbene und Hinterbliebene:</h2>
            <ul>
              <li>Sofortige Betreuung im Todesfall, kompetente und ausführliche Beratung</li>
              <li>Beratung auch in&nbsp;<span className="text-primary text-bold">polnischer Sprache</span></li>
              <li>Überführung des Verstorbenen im In- und Ausland zu jedem gewünschten Ort</li>
              <li>Hausbesuche nach telefonischer Absprache</li>
              <li>24-Stunden Bereitschaftstelefon, auch an allen Wochenenden und Feiertagen</li>
            </ul>
            <h2>Einzelheiten der Bestattung und organisatorische Abwicklung:</h2>
            <ul>
              <li>Komplette Organisation der Bestattung und der Trauerfeier</li>
              <li>Kühlung möglich</li>
              <li>Erd- oder Feuerbestattungen mit anschließender Beisetzung</li>
              <li>Bereitstellen von Sarg, Urne und sonstigen Devotionalien</li>
              <li>Pietätvolle Aufbahrungen</li>
              <li>Anfertigung von Totenmasken</li>
              <li>Beratung bei der Festlegung eines Grabes</li>
              <li>Umfassende Beratung bei der Herstellung von Trauerdrucken- und anzeigen</li>
              <li>Bestellung von Blumenschmuck und Dekorationen</li>
              <li>Terminabsprachen mit Pfarrern, Rednern und Musikern</li>
              <li>Übergabe von Kondolenzkarten und Urkunden</li>
              <li>Vorsorgeberatung und Trauerbegleitung</li>
            </ul>
            <h2>Regelungen mit Behörden Ämtern und Versicherungen:</h2>
            <ul>
              <li>Erledigung sämtlicher Formalitäten, Terminabstimmungen und Behördengänge</li>
              <li>Abrechnung der Sterbegelder mit allen Versicherungen und Abmeldung bei, Krankenkassen, Gewerkschaften etc.</li>
              <li>Beschaffung der ärztlichen Papiere</li>
              <li>Sterbefallanzeige, Sterbefall-Beurkundung beim Standesamt</li>
              <li>Genehmigung zur Feuerbestattung</li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
}
