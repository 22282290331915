import landingImage from "../../../Assets/Ruhestaetten_landing_img.jpg";
import LandingImageHeader from "../../../Components/LandingImageHeader/LandingImageHeader";
import "./Ruhestätten.scss";
import SideNav from "../../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Ruhestaetten() {
    return (
        <div className="ruhestaetten-p">
            <Helmet>
                <title>Ruhestätten | Bestattungen Lichtblick</title>
                <meta name='description' content='Hier finden Sie eine Übersicht von Ruhestätten in NRW. Jetzt informieren!' />
            </Helmet>
            <LandingImageHeader
                img={landingImage}
                alt={"Ein Baum mitten auf einem Feld. Natur, Ruhe und Weitblick in einer malerischen Landschaft."}
                text={`Ruhestätten in NRW`}
            />
            <div className="flex-div">
                <SideNav />
                <div tabIndex={0} id="page-content" className="page-content">
                    <article>
                        <h2>Hier finden Sie eine Übersicht von Ruhestätten in NRW:</h2>
                        <p className="text-center">
                            <strong className="text-primary">FriedWald - Die Bestattung in der Natur</strong><br />
                            Bad Münstereifel<br />
                            Bad Laasphe<br />
                            Kalletal<br />
                            Münsterland<br />
                            Lohmar<br />
                            Möhnesee<br /><br />
                            <strong className="text-primary">Ruheforst</strong><br />
                            Hilchenbach - Siegerland<br />
                            Bad Driburg<br />
                            Hagen Philippshöhe<br />
                            Westmünsterland - Coesfeld<br /><br />
                            <strong className="text-primary">RuhestätteNatur - Herten-Westerholt</strong><br />
                            Herten Westerholt<br />
                            Haltern Sythen
                        </p>
                    </article>

                    <p className="warnPara">
                        Hinweis:
                        <br />
                        Selbstverständlich können wir die Urne ohne Aufpreis auch in andere Orte überführen.
                    </p>

                </div>
            </div>
        </div>
    );
}
