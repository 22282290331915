import "./App.scss";
import NavbarComp from "./Components/Navbar/Navbar";
import { useContext, useEffect, useState } from "react";
import { AccessibilityContext } from "./context/accessibilityContext";
import Footer from "./Components/Footer/Footer";
import RenderRoutes from "./Pages/router";
import AccessibilityMenu from "./Components/AccessibilityMenu/AccessibilityMenu";
import { HelmetProvider } from 'react-helmet-async';
import GoogleTagManager from "./Components/GoogleTagManager/GoogleTagManager";
import CookieConsentForm from "./Components/CookieConsent/CookieConsent";
import { usePageTracking } from "./Components/GoogleTagManager/usePageTracking";

function App() {
  const { fontSizeMag } = useContext(AccessibilityContext);
  const helmetContext = {};
  usePageTracking();

  useEffect(() => {
    document.documentElement.style.fontSize = `${fontSizeMag}px`;
  }, [fontSizeMag]);

  const [consents, setConsents] = useState<string[]>([]);

  const handleConsentGranted = (consentTypes: string[]) => {
    setConsents(consentTypes);
  };

  const handleConsentDenied = () => {
    setConsents([]);
    console.log("All cookies denied");
  };

  return (
    <HelmetProvider context={helmetContext}>

      <div className="App">
        <CookieConsentForm
          onConsentGranted={handleConsentGranted}
          onConsentDenied={handleConsentDenied}
        />
        <GoogleTagManager
          gtmId="GTM-KTP323FT"
          analyticsConsent={consents.includes('consent_analytics')}
          marketingConsent={consents.includes('consent_marketing')}
        />
        <NavbarComp />
        <AccessibilityMenu />
        <RenderRoutes />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;
