export const navRoutesData = [
  { path: "/Bestattungsarten", pathName: "Bestattungsarten" },
  { path: "/Trauerfall", pathName: "Trauerfall" },
  { path: "/Leistungen", pathName: "Leistungen" },
  {
    path: "/Komplettangebote",
    pathName: "Komplettangebote",
    subPath: ["Feuerbestattungen", "Erdbestattungen", "Anonyme-Bestattung"],
  },
  { path: "/Seebestattungen", pathName: "Seebestattungen" },
  {
    path: "/Waldbestattungen",
    pathName: "Waldbestattungen",
    subPath: ["Ruhestätten", "Beisetzungskosten"],
  },
  {
    path: "/Bestattungen-NRW",
    pathName: "Bestattungen NRW",
  },
  { path: "/Überführungen", pathName: "Überführungen" },
  { path: "/Haushaltsauflösung", pathName: "Haushaltsauflösung" },
  { path: "/Finanzierung", pathName: "Finanzierung" },
];
