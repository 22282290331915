import "./Footer.scss";
import { useContext } from "react";
import { AccessibilityContext } from "../../context/accessibilityContext";
import { Link } from "react-router-dom";

export default function Footer() {
  const { displaySize } = useContext(AccessibilityContext);
  return (
    <section className="footer-c">
      <div>
        <p className="nameInfo">
          Bestattungen Lichtblick
          <br />
          Inhaber: Paul Holz
        </p>
        <p>
          Frauenlobstr. 49
          <br />
          44805 Bochum
        </p>
        <p>
          <Link to="tel:+492349580004">Telefon 0234 - 958 00 04</Link>
          <br />
          <Link to="mailto:info@bestattungen-lichtblick.de">info@bestattungen-lichtblick.de</Link>
        </p>
        {displaySize.isDesktop ? (
          <p>
            <Link to="/Impressum">Impressum</Link> |{" "}
            <Link to="/Datenschutz">Datenschutz</Link>|{" "}
            <Link to="/Site-Navigation">Sitemap</Link>
          </p>
        ) : (
          <></>
        )}
      </div>
      {displaySize.isDesktop ? (
        <></>
      ) : (
        <p className="text-center">
          <Link to="/Impressum">Impressum</Link> |{" "}
          <Link to="/Datenschutz">Datenschutz</Link>|{" "}
          <Link to="/Site-Navigation">Sitemap</Link>
        </p>
      )}
    </section>
  );
}
