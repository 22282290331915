export const contentData = {
  h3_1: "Komplettangebot Feuerbestattung",
  p1: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
  diam nonumy eirmod tempor invidunt ut labore et dolore magna
  aliquyam erat, sed diam voluptua. At vero eos et accusam et
  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
  ipsum dolor sit amet.`,
  h3_2: "Komplettangebot Feuerbestattung",
  h3_3: "Inhalt des Angebotes",
  ul1: [
    `Kiefernsarg inkl. Bespannung`,
    `Decke und Kissen`,
    `Sterbehemd, Verwendung von Privatkleidung ist möglich`,
    `Schmuckurnen zur Beisetzung`,
    `Hausbesuch mit individueller Beratung (im Umkreis von 20 km)`,
    `Umfassende Beratung in allen Bestattungsfragen`,
    `Fahrer und Beifahrer für die Überführung`,
    `Überführung vom Sterbeort zum Krematorium (eventuelle
    Mehrkosten, unter Bestattung NRW erhalten Sie genauere
    Informationen)`,
    `Rückführung der Urne zum Friedhof`,
    `Benachrichtigung der Krankenkasse`,
    `Abmeldung der Rentenansprüche`,
  ],
  ul2: [
    `Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen`,
    `Beurkundung beim zuständigen Standesamt`,
    `Einholung und Überprüfung der Todesbescheinigung`,
    `Besorgen der amtsärztlichen Leichenschau`,
    `Aufnahme von Traueranzeigen`,
    `Absprache aller notwendigen Termine`,
    `Benachrichtigung der zuständigen Friedhofsverwaltung`,
    `Koordination der Trauerfeier mit der Kirchengemeinde`,
    `Urnenträger zur Beisetzung`,
    `Trägerhandschuhe`,
    `Auf Wunsch Fotos von der Trauerfeier`,
    `Betreuung und Dienste auf dem Friedhof`,
  ],
};
