import landingImage from "../../Assets/Bestattungen_NRW_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Bestattungen-NRW.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function bestattungenNRW() {
  return (
    <div className="bestattungen-nrw-p">
      <Helmet>
        <title>Bestattungen in NRW | Bestattungen Lichtblick</title>
        <meta name='description' content='Für die jeweilgen Regionen in NRW berechnen wir verschiedene Zuschläge. Jetzt informieren!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Gras in der Sonne mit unscharfem Hintergrund."}
        text={`Zuschläge in den einzelnen Regionen in NRW für eine Beisetzung`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <p>
              Bei der anonymen Bestattung für 1.150 € der Seebestattung sowie bei der Waldbestattung kommen von unserer Seite keine weiteren Überführungskosten hinzu.
            </p>
            <h2>Aufschläge für Beisetzungen mit Trauerfeier:</h2>
            <p>
              Düsseldorf: 200,00 €<br />
              Bergisches Land: 230,00 €<br />
              Köln: 250,00 €<br />
              Bonn: 300,00 €<br />
              Eifel: 350,00 €<br />
              Niederrhein: 250,00 €<br />
              Münsterland: 300,00 €<br />
              Sauerland: 250,00 €<br />
              Teutoburger Wald: 350,00 €<br />
              Siegerland - Wittgenstein: 300,00 €<br />
            </p>

          </article>
          <article className="warnPara">
            <p>
              WICHTIGE INFORMATION:
              <br />
              Da gewisse Regionen einen größeren Umkreis haben, ist es uns nicht möglich pauschal einen Preis für eine Region zu nennen. Nehmen Sie mit uns Kontakt auf, um einen genauen Preis für Ihren Fall zu erfragen.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}
