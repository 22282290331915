import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import Beisetzungskosten from "./Beisetzungskosten/Beisetzungskosten";
import Ruhestaetten from "./Ruhestätten/Ruhestätten";
import Waldbestattungen from "./Waldbestattungen";

const RenderWaldbestattungenRoutes = () => {
    const routesData = useMemo(
        () => [
            {
                path: "/",
                element: <Waldbestattungen />,
            },
            {
                path: "/Ruhestätten",
                element: <Ruhestaetten />,
            },
            {
                path: "/Beisetzungskosten",
                element: <Beisetzungskosten />,
            }
        ],
        []
    );

    return (
        <Routes>
            {routesData.map((el, ind) => {
                return (
                    <Route key={`route${ind}`} path={el.path} element={el.element} />
                );
            })}
        </Routes>
    );
};

export default RenderWaldbestattungenRoutes;

