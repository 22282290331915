import "./DetailsBtn.scss";
import arrowSvg from "../../Assets/arrow.svg";
import { Link } from "react-router-dom";

export default function DetailsBtn({
  btnName = "weitere Details",
  link = "/",
  target = "_self",
}) {
  return (
    <Link className="btn" to={link} title={link} target={target} >
      {btnName} <img src={arrowSvg} alt={btnName} />
    </Link>
  );
}
