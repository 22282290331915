export const mainHeadline = 'Unsere Leistungen'

export const packagesNordsee = [
    {
        packageType: "Seebestattung in der Nordsee",
        packages: [
            {
                packageName: "Seebestattung in der Nordsee",
                price: "1.150",
                services: "inkl. Krematorium & Beisetzung",
                link: "/Komplettangebote/Feuerbestattungen",
                isStar: false,
            },
        ],
    },
];

export const packagesOstsee = [
    {
        packageType: "Seebestattung in der Ostsee",
        packages: [
            {
                packageName: "Seebestattung in der Ostsee",
                price: "1.350",
                services: "inkl. Krematorium & Beisetzung",
                link: "/Komplettangebote/Feuerbestattungen",
                isStar: false,
            },
        ],
    },
];

export const packagesSeeFamilie = [
    {
        packageType: "Seebestattung in der Nordsee ab Den Haag mit Angehörigen",
        packages: [
            {
                packageName: "Seebestattung in der Nordsee ab Den Haag mit Angehörigen",
                price: "1.800",
                services: "inkl. Krematorium & Beisetzung",
                link: "/Komplettangebote/Feuerbestattungen",
                isStar: false,
            },
        ],
    },
];