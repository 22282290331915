import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import landingImage from "../../Assets/Home_landing_img.jpg";
import "./404.scss";
import { Link } from "react-router-dom";
import SideNav from "../../Components/SideNav/SideNav";
import {Helmet} from "react-helmet-async";
import {useContext} from "react";
import {AccessibilityContext} from "../../context/accessibilityContext";

export default function NotFound() {
    const { displaySize } = useContext(AccessibilityContext);

    return (
        <div className="not-found-p">
            <Helmet>
                <title>404 | Bestattungen Lichtblick</title>
                <meta name='description' content='Seite nicht gefunden!' />
            </Helmet>
            <LandingImageHeader
                img={landingImage}
                alt={"Eine Stapel Steine mit einer lila Blume daneben."}
                text={`Bestattungen Lichtblick - Ihr Bestattungsunternehmen in Bochum`}
            />
            <div className="flex-div">
                <SideNav />
                <div tabIndex={0} id="page-content" className="page-content">
                    <article>
                        <h2>Seite nicht gefunden!</h2>
                        <p>Die von Ihnen gesuchte Seite konnte nicht gefunden werden.</p>
                        {displaySize.isDesktop ?
                            <p>Im Menü auf der linken Seite finden Sie weitere Informationen zu den Bestattungsarten und Angeboten.</p>
                            : <></>}
                    </article>
                </div>
            </div>
        </div>
    )
}