export const trauerfallData = {
  p1: `Folgende Papiere und Unterlagen werden zur Ausführung der
    notwendigen Formalitäten im Zusammenhang mit der Bestattung
    benötigt:`,
  h3_1: `Sterbefall im Krankenhaus`,
  p3: `Wenn Ihr Angehöriger im Krankenhaus verstorben ist, wird dort von
  einem Arzt die Todesbescheinigung ausgestellt. In Alten- oder
  Pflegeheimen wird der Arzt gerufen, der den Verstorbenen kennt. In
  Krankenhäusern darf der Bestatter erst nach einer bestimmten Frist
  den Verstorbenen zum Friedhof überführen bzw. erhält auch dann erst
  die Todesbescheinigung und weitere Unterlagen. In größeren
  Krankenhäusern oder Kliniken kann man an Wochenenden sehr wenig
  erledigen, so dass man erst am nächsten Werktag dort aktiv werden
  kann.`,
  p4: `Bei Todesfällen in Krankenhäusern, ambulanten Pflegestationen,
  Seniorenheimen, etc., können Sie sich gleich mit uns in Verbindung
  setzen, um die weiterführenden Maßnahmen zu besprechen.`,
  h3_2: "Sterbefall in der Wohnung",
  p5: `Besonders seit den letzen Jahren, in denen viele Menschen zu Hause
  gepflegt werden, müssen sich die Angehörigen der Situation des Todes
  eines Menschen in ihrer unmittelbaren Umgebung stellen. Für viele
  Menschen ist diese Situation neu und überraschend. Damit für den
  Verstorbenen und die Angehörigen alles in Ruhe geregelt wird,
  möchten wir Ihnen kurz schildern, was zuerst getan werden muss.
  Bitte benachrichtigen Sie sofort einen Arzt. Wenn möglich, den
  Hausarzt, der den Verstorbenen zuvor gekannt hat. Wenn dieser nicht
  erreichbar ist, das kann z.B. an Wochenenden oder nachts sein, rufen
  sie den Notarzt. Dieser ist unter der Nummer der Feuerwehr 112 zu
  benachrichtigen. Der Hausarzt oder der Notarzt wird nach einer
  Untersuchung gegebenenfalls den Tod feststellen. Der Arzt ist dann
  verpflichtet, dies auch schriftlich zu dokumentieren. Es wird eine
  Todesbescheinigung ausgestellt. Dazu braucht der Arzt eventuell den
  Personalausweis des Verstorbenen, wenn er den Verstorbenen nicht
  gekannt hat. Ist der Angehörige zu Hause gestorben, so erlaubt der
  Gesetzgeber, dass der Verstorbene bis zu 36 Stunden im Hause bleiben
  darf.`,
  p6: `Kontaktieren Sie uns nach spätestens 36 Stunden, damit wir uns um
  den weiteren Ablauf kümmern können.`,
  h3_3: "Andere Umstände",
  p7: `Falls der Verstorbene eines nicht natürlichen Todes gestorben ist
  oder die Todesursache nicht geklärt ist, wird grundsätzlich die
  Polizei informiert. Wenn die Polizei nicht, wie z.B. bei einem
  Unfall schon vor Ort ist, wird sie meistens vom Notarzt gerufen. Die
  Polizei ermittelt und wird gegebenenfalls notwendiges veranlassen.`,
};
