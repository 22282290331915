import landingImage from "../../Assets/Bestattungsarten_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Bestattungsarten.scss";
import { p1Content, bestattungenTypeData } from "./content-Bestattungsarten";
import BestattungenType from "../../Components/BestattungenType/BestattungenType";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Bestattungsarten() {
  return (
    <div className="bestattungsarten-p page">
        <Helmet>
            <title>Bestattungsarten | Bestattungen Lichtblick</title>
            <meta name='description' content='Welche Dokumente sind wichtig für die Bestattung? Hier finden Sie eine Übesicht. Jetzt informieren!' />
        </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Detailaufnahme eines Löwenzahns vor weißem Hintergrund."}
        text={`Wir ermöglichen eine Vielfalt an Bestattungsarten –
Sprechen Sie uns an.`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <p className="p1">{p1Content.p1_1}</p>
          {bestattungenTypeData.map((el, ind) => {
            return (
              <BestattungenType
                key={ind}
                typeTitle={el.typeTitle}
                data={el.data}
              />
            );
          })}

          <p className="warnPara">
            DAS SOLLTEN SIE WISSEN:
            <br />
            Über alle Voraussetzungen und Besonderheiten informieren wir Sie
            gern ausführlich, kostenlos und unverbindlich.
          </p>
        </div>
      </div>
    </div>
  );
}
