import landingImage from "../../Assets/Uber_uns_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Über-uns.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';
import {Link} from "react-router-dom";

export default function Ueberuns() {
    return (
        <div className="ueberuns-p">
            <Helmet>
                <title>Über uns | Bestattungen Lichtblick</title>
                <meta name='description' content='Bestattungen Lichtblick aus Bochum stellt sich vor. Jetzt informieren!' />
            </Helmet>
            <LandingImageHeader
                img={landingImage}
                alt={"Eine Stapel Steine mit einer lila Blume daneben."}
                text={`Bestattungen Lichtblick - Ihr Bestattungsunternehmen in Bochum`}
            />
            <div className="flex-div">
                <SideNav />
                <div tabIndex={0} id="page-content" className="page-content">
                    <article>
                        <p>Bestattungen Lichtblick in Bochum ist ein etabliertes und einfühlsames Bestattungsunternehmen, das sich durch seine Professionalität und Menschlichkeit auszeichnet. Gegründet mit dem Ziel, Trauernden in einer der schwierigsten Zeiten ihres Lebens zur Seite zu stehen, bietet Bestattungen Lichtblick umfassende Dienstleistungen, die individuell auf die Bedürfnisse der Hinterbliebenen abgestimmt sind.<br />

                            Das Unternehmen legt großen Wert auf persönliche Betreuung und einfühlsame Beratung. Jede Beisetzung wird mit höchster Sorgfalt und Respekt geplant und durchgeführt. Das Team von Bestattungen Lichtblick versteht, dass jeder Mensch und jede Familie einzigartig ist, und gestaltet daher Abschiedszeremonien, die die Persönlichkeit und das Leben des Verstorbenen würdigen.<br />

                            Ein zentrales Anliegen von Bestattungen Lichtblick ist es, den Trauerprozess so einfach und würdevoll wie möglich zu gestalten. Dazu gehört eine umfassende Beratung, die alle Aspekte der Bestattung abdeckt – von der Wahl der Bestattungsform über die Gestaltung der Trauerfeier bis hin zur Regelung sämtlicher Formalitäten. Das Unternehmen bietet verschiedene Bestattungsarten an, darunter <Link to="/Komplettangebote/Erdbestattungen">Erdbestattungen</Link>, <Link to="/Komplettangebote/Erdbestattungen">Feuerbestattungen</Link> und <Link to="/Seebestattungen">Seebestattungen</Link> sowie <Link to="Bestattungsarten">alternative Bestattungsformen</Link>, die den individuellen Wünschen und Vorstellungen gerecht werden.<br />

                            Darüber hinaus unterstützt Bestattungen Lichtblick die Angehörigen auch nach der Beisetzung. Das Team steht für Trauerbegleitung zur Verfügung und hilft bei der Organisation von Trauerkaffees oder Gedenkveranstaltungen.</p>
                    </article>
                </div>
            </div>
        </div>
    );
}
