
import styles from "./Site-Navigation.module.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export default function SiteNavigation() {
  return (
    <>
      <Helmet>
        <title>IPA Pflegedienst - Navigation</title>
        <meta name="keywords" content="IPA Pflegedienst - Navigation" />
      </Helmet>

      <section className={"d-flex flex-column " + styles.siteMap}>
        <h1>Sitemap</h1>
        <nav className={styles.sitemap}>
          <ul>
            <li className={styles.item}><Link to="/">Start</Link></li>
            <li className={styles.item}><Link to="/Bestattungsarten">Bestattungsarten</Link></li>
            <li className={styles.item}><Link to="/Trauerfall">Trauerfall</Link></li>
            <li className={styles.item}><Link to="/Leistungen">Leistungen</Link></li>
            <li className={styles.item}><Link to="/Komplettangebote">Komplettangebote</Link>
              <ul>
                <li className={styles.subItem}><Link to="/Komplettangebote/Feuerbestattungen">Feuerbestattungen</Link></li>
                <li className={styles.subItem}><Link to="/Komplettangebote/Erdbestattungen">Erdbestattungen</Link></li>
                <li className={styles.subItem}><Link to="/Komplettangebote/Anonyme-Bestattung">Anonyme-Bestattung</Link></li>
              </ul>
            </li>
            <li className={styles.item}><Link to="/Seebestattungen">Seebestattungen</Link></li>
           
          </ul>
          <ul>
          <li className={styles.item}><Link to="/Waldbestattungen">Waldbestattungen</Link>
              <ul>
                <li className={styles.subItem}><Link to="/Waldbestattungen/Ruhestätten">Ruhestätten</Link></li>
                <li className={styles.subItem}><Link to="/Waldbestattungen/Beisetzungskosten">Beisetzungskosten</Link></li>
              </ul>
            </li>
            <li className={styles.item}><Link to="/Bestattungen-NRW">Bestattungen-NRW</Link></li>
            <li className={styles.item}><Link to="/Überführungen">Überführungen</Link></li>
            <li className={styles.item}><Link to="/Haushaltsauflösung">Haushaltsauflösung</Link></li>
            <li className={styles.item}><Link to="/Über-uns">Über-uns</Link></li>
            <li className={styles.item}><Link to="/impressum">impressum</Link></li>
            <li className={styles.item}><Link to="/datenschutz">datenschutz</Link></li>
          </ul>
        </nav>
      </section>
    </>
  );
}
