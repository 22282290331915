import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import Home from "./Home/Home";
import Bestattungsarten from "./Bestattungsarten/Bestattungsarten";
import RenderKomplettangeboteRoutes from "./Komplettangebote/router-Komplettangebote";
import Trauerfall from "./Trauerfall/Trauerfall";
import Leistungen from "./Leistungen/Leistungen";
import Seebestattungen from "./Seebestattungen/Seebestattungen";
import RenderWaldbestattungenRoutes from "./Waldbestattungen/router-Waldbestattungen"
import BestattungenNRW from "./Bestattungen-NRW/Bestattungen-NRW";
import Überführungen from "./Überführungen/Überführungen";
import Haushaltsauflösung from "./Haushaltsauflösung/Haushaltsauflösung";
import Finanzierung from "./Finanzierung/Finanzierung";
import Impressum from "./Impressum/Impressum";
import Datenschutz from "./Datenschutz/Datenschutz";
import Ueberuns from "./Über-uns/Über-uns";
import SiteNavigation from "./Site-Navigation/Site-Navigation";
//import NotFound from "../../../bestattungen-lichtblick-main/src/Pages/404/404";
import NotFound from "./404/404";
// import Kontakt from "./Kontakt/Kontakt";

const RenderRoutes = () => {
  const routesData = useMemo(
    () => [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/Bestattungsarten",
        element: <Bestattungsarten />,
      },
      {
        path: "/Trauerfall",
        element: <Trauerfall />,
      },
      {
        path: "/Leistungen",
        element: <Leistungen />,
      },
      {
        path: "/Komplettangebote/*",
        element: <RenderKomplettangeboteRoutes />,
      },
      {
          path: "/Seebestattungen",
          element: <Seebestattungen />,
      },
      {
          path: "/Waldbestattungen/*",
          element: <RenderWaldbestattungenRoutes />,
      },
      {
          path: "/Bestattungen-NRW",
          element: <BestattungenNRW />,
      },
      {
          path: "/Überführungen",
          element: <Überführungen />,
      },
      {
          path: "/Haushaltsauflösung",
          element: <Haushaltsauflösung />,
      },
      {
          path: "/Finanzierung",
          element: <Finanzierung />,
      },
      {path:"/Site-Navigation",
        element:<SiteNavigation/>
      },
      {
        path: "/Über-uns",
        element: <Ueberuns />,
      },
      /*{
        path: "/kontakt",
        element: <Kontakt />,
      },*/
        {
            path: "/impressum",
            element: <Impressum />,
        },
        {
            path: "/datenschutz",
            element: <Datenschutz />,
        },
      {
          path: "*",
          element: <NotFound />,
      },
    ],
    []
  );

  return (
    <Routes>
      {routesData.map((el, ind) => {
        return (
          <Route key={`route${ind}`} path={el.path} element={el.element} />
        );
      })}
    </Routes>
  );
};

export default RenderRoutes;
