import landingImage from "../../Assets/Home_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Home.scss";
import PriceModel from "../../Components/PriceModel/PriceModel";
import DetailsBtn from "../../Components/Btn/DetailsBtn";
import SideNav from "../../Components/SideNav/SideNav";
import { useContext } from "react";
import { AccessibilityContext } from "../../context/accessibilityContext";
import { Helmet } from 'react-helmet-async';

import {
  packagesData,
  info_2,
  info_3,
} from "./content-Home";
import {Link} from "react-router-dom";

export default function Home() {
  const { displaySize } = useContext(AccessibilityContext);

  return (
    <div className="home-p page">
      <Helmet>
        <title>Bestattungen Lichtblick</title>
        <meta name='description' content='Bestattungen Lichtblick preisbewusst und würdevoll. Wir führen Bestattungen in ganz NRW durch. Jetzt kontaktieren!' />
      </Helmet>
      <LandingImageHeader
          img={landingImage}
          alt={"Sonnenstrahlen durchdringen den Herbstwald, während sie zwischen den Bäumen hindurchscheinen."}
          text='Bestattungen Lichtblick – Bestattungen aller Art zu günstigen Konditionen in ganz Nordrhein-Westfalen.'
      />
      <div className="flex-div">
        {displaySize.isDesktop ? <SideNav /> : <></>}
        <div id="page-content" tabIndex={0} className="home-content">
          <article>
            <p>Liebe Kunden/innen,<br/>
              wir möchten, dass Sie Abschied nehmen können, in der Gewissheit, einen Bestatter ausgewählt zu haben, der Sie nicht nur persönlich unterstützt, sondern auch dafür sorgt, Ihre Kosten niedrig zu halten.</p>
            <p>Wir bieten Ihnen drei Komplettangebote, die alle Leistungen abdecken, die für eine Bestattung erforderlich sind. Bei uns haben Sie auch die Möglichkeit Seebestattungen und Waldbestattungen durchführen zulassen, dazu stehen Ihnen ebenfalls Komplettangebote zur Auswahl.</p>
            <p>Die Bestattungsangebote gelten für den Kreis Bochum und Umgebung, für andere Teile von Nordrheinwestfalen kommen Überführungskosten hinzu die Sie unter dem Punkt Bestattung NRW entnehmen können.</p>
            <p><strong>Für die anonyme Bestattung, die Seebestattung sowie bei der Waldbestattung entstehen in ganz NRW keine weiteren Überführungskosten.</strong></p>
          </article>
          {packagesData.map((el, ind) => {
            return (
              <section className="package-type" key={ind}>
                <h3 className="t2">
                  {el.ven && !displaySize.isMobile
                    ? el.packageType + el.ven
                    : el.packageType}
                  {el.ven && !displaySize.isMobile ? <></> : <br />}
                  {el.ven && !displaySize.isMobile ? <></> : el.ven}
                </h3>
                <div className="package">
                  {el.packages.map((el, indIn) => {
                    return (
                      <PriceModel
                        key={indIn}
                        heading={el.packageName}
                        price={el.price}
                        services={el.services}
                        link={el.link}
                        isStar={el.isStar}
                      />
                    );
                  })}
                </div>
              </section>
            );
          })}
          <article>
            <p>Die Komplettangebote sind jederzeit erweiterbar und können nach Ihren Wünschen gestaltet werden. In einem persönlichen Gespräch können wir am besten besprechen welche Möglichkeiten für Sie in Frage kommen. Wir führen auch bundesweit & international Bestattungen aus, einzelne Preise erfragen Sie bitte telefonisch oder per Email. Zudem bieten wir Ihnen die Möglichkeit einer Finanzierung bis zu 72 Monaten an. Weitere Informationen unter <Link to="/Komplettangebote">Komplettangebote</Link>, <Link to="/Seebestattungen">Seebestattungen</Link>, <Link to="Waldbestattungen">Waldbestattungen</Link> und <Link to="/Finanzierung">Finanzierung</Link>. Wir sind Tag und Nacht für Sie erreichbar.</p>
          </article>
          <p className="info_1">*Andere Bundesländer gerne auf Anfrage!</p>
          <article className="info_2">
            <p>
              WICHTIGE INFORMATION:
              <br />
              {info_2}
            </p>
          </article>
          <article className="info_3">
            <p className="info_3_1">
              DAS SOLLTEN SIE WISSEN:
              <br />
              {info_3.i1_1}
              <br />
              {info_3.i1_2}
            </p>
            <div className="line"></div>
            <p className="info_3_2">
              WELTWEITE ÜBERFÜHRUNG & RÜCKHOLUNG VON VERSTORBENEN:
              <br />
              {info_3.i2_1}
            </p>
            <DetailsBtn link="Überführungen" />
          </article>
        </div>
      </div>
    </div>
  );
}
