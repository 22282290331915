import landingImage from "../../../Assets/Erdbestattung_landing_img.jpg";
import LandingImageHeader from "../../../Components/LandingImageHeader/LandingImageHeader";
import "./Erdbestattungen.scss";
import SideNav from "../../../Components/SideNav/SideNav";
import PriceModel from "../../../Components/PriceModel/PriceModel";
import { Helmet } from 'react-helmet-async';
import { contentData } from "./content-Erdbestattungen";

export default function Erdbestattungen() {
  return (
    <div className="flex-column feuerbestattungen-p page">
      <Helmet>
        <title>Erdbestattung | Bestattungen Lichtblick</title>
        <meta name='description' content='Mit einer Erdbestattung Abschied auf eine würdevolle Weise nehmen. Jetzt Angebot anfordern!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Grüne Graslandschaft mit blauem Himmel im Hintergrund."}
        text={`Die Erdbestattung – eine traditionelle und respektvolle Form des Abschieds.`}
      />
      <div className="flex-div flex-row">
        <SideNav />
        <div
          tabIndex={0}
          id="page-content"
          className="page-content flex-column"
        >
          <div className="price-block ">
            <article className="margin-div flex-column">
              <h3>{contentData.h3_2}</h3>
              <div className="content flex-column">
                <PriceModel
                  heading="Erdbestattung mit Trauerfeier & Sarg"
                  price="1.800"
                  services="zzgl. städt. Kosten"
                  hasBtn={false}
                />
                <p className="p1">Bestattungen Lichtblick bietet im Trauerfall angemessene Dienstleistungen für Erdbestattungen an, um Abschied auf eine würdevolle Weise zu nehmen. Von der Wahl des passenden Grabes bis zur Organisation der Trauerfeier begleiten wir Sie einfühlsam in dieser schweren Zeit. Unser erfahrenes Bestattungsteam steht Ihnen zur Seite, um alle Formalitäten zu regeln und den Prozess so reibungslos wie möglich zu gestalten. Wir legen Wert auf individuelle Wünsche und sorgen dafür, dass der Abschiedsritus den persönlichen Vorstellungen entspricht. Vertrauen Sie auf unsere Erfahrung, um einen liebevollen und harmonischen Abschied zu ermöglichen. Ihre Wünsche stehen im Mittelpunkt unseres Handelns – für eine Erdbestattung, die dem Gedenken an den Verstorbenen gerecht wird.</p>
              </div>
            </article>
          </div>
          <div className="details-ul flex-column">
            <h3>{contentData.h3_3}</h3>
            <div className="bullet-point ">
              <ul>
                <li>Kiefernsarg für eine Erdbestattung</li>
                <li>Decke und Kissen</li>
                <li>Sterbehemd, Verwendung von Privatkleidung ist möglich</li>
                <li>Desinfektion</li>
                <li>Einbetten / Einkleiden</li>
                <li>Individuelle Beratung</li>
                <li>Umfassende Beratung in allen Bestattungsfragen</li>
                <li>Fahrer und Beifahrer für die Überführung</li>
                <li>Überführung vom Sterbeort zum Friedhof (eventuelle Mehrkosten, unter Bestattung NRW erhalten Sie genauere Informationen)</li>
                <li>Benachrichtigung der Krankenkasse</li>
                <li>Abmeldung der Rentenansprüche</li>
                <li>Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen</li>
              </ul>
              <ul>
                <li>Beurkundung beim zuständigen Standesamt</li>
                <li>Einholung und Überprüfung der Todesbescheinigung</li>
                <li>Einholung aller benötigten Unterlagen für die Erdbestattung</li>
                <li>Aufnahme von Traueranzeigen</li>
                <li>Absprache aller notwendigen Termine</li>
                <li>Benachrichtigung der zuständigen Friedhofsverwaltung</li>
                <li>Koordination der Trauerfeier mit der Kirchengemeinde</li>
                <li>Sargträger zur Beisetzung</li>
                <li>Trägerhandschuhe</li>
                <li>Auf Wunsch Fotos von der Trauerfeier</li>
                <li>Betreuung und Dienste auf dem Friedhof</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
