import React, { useState, createContext, useEffect } from "react";

export const AccessibilityContext = createContext();

export function AccessibilityProvider(props) {
  const mobileWidth = 450;
  const desktopWidth = 1366;
  //change to 1366 after development
  const [darkTheme, setDarkTheme] = useState(false);
  const [fontSizeMag, setFontSizeMag] = useState(16);
  const [displaySize, setDisplaySize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= mobileWidth ? true : false,
    isTablet:
      window.innerWidth > mobileWidth && window.innerWidth <= desktopWidth
        ? true
        : false,
    isDesktop: window.innerWidth > desktopWidth ? true : false,
  });
  useEffect(() => {
    const handleResize = () => {
      setDisplaySize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= mobileWidth ? true : false,
        isTablet:
          window.innerWidth > mobileWidth && window.innerWidth <= desktopWidth
            ? true
            : false,
        isDesktop: window.innerWidth > desktopWidth ? true : false,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setDisplaySize]);
  function toggleTheme() {
    setDarkTheme(!darkTheme);
  }
  function increaseFontSize() {
    fontSizeMag >= 16 && fontSizeMag < 26
      ? setFontSizeMag(fontSizeMag + 2)
      : setFontSizeMag(fontSizeMag);
  }
  function decreaseFontSize() {
    fontSizeMag > 16 && fontSizeMag <= 26
      ? setFontSizeMag(fontSizeMag - 2)
      : setFontSizeMag(fontSizeMag);
  }
  return (
    <AccessibilityContext.Provider
      value={{
        darkTheme,
        toggleTheme,
        fontSizeMag,
        decreaseFontSize,
        increaseFontSize,
        displaySize,
      }}
    >
      {props.children}
    </AccessibilityContext.Provider>
  );
}
