import landingImage from "../../Assets/Ueberfuehrung_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Überführungen.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';
import DetailsBtn from "../../Components/Btn/DetailsBtn";
import React from "react";

export default function Ueberfuehrung() {
  return (
    <div className="ueberfuehrungen-p">
        <Helmet>
            <title>Überführung & Rückführung | Bestattungen Lichtblick</title>
            <meta name='description' content='Überführung von Verstorbenen ins Ausland oder Rückführung in die Heimat. Jetzt informieren!' />
        </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Rote Mohnblumen in einem Feld mit hohem Gras"}
        text={`Überführung und Rückführung der/des Verstorbenen`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <h2>Unsere Unterstützung bei Überführungen aus dem Ausland</h2>
            <p>Wir übernehmen die Überführung von Verstorbenen ins Ausland oder Heimschaffung von im Ausland Verstorbenen zu günstigen Konditionen.<br />
              Wir pflegen europaweit Kontakte zu Deutschen Botschaften, Generalkonsulaten und Honorarkonsulaten. Wir garantieren deshalb auch die seriöse und ordnungsgemäße Durchführung von Aufträgen im Sinne der internationalen Abkommen (Einholung aller erforderlichen Dokumente).<br />
              Wir sorgen mit qualifiziertem Personal und modernen Überführungsfahrzeugen für eine möglichst rasche Überführung in die Heimat. Durch den Kontakt zu erfahrenen Überführern die bundesweit für uns tätig sind, können wir in ganz Deutschland Verstorbene abholen und durch die Zusammenarbeit mit qualifizierten Luftfrachtspeditionen, in alle Erdteile überführen. Selbstverständlich erledigen wir für Sie auch alle damit verbundenen Formalitäten.</p>
            <h2> Rückführung  der/des Verstorbenen in die Heimat</h2>
            <p>In unserer mobilen Gesellschaft ereilt der Tod die Menschen zunehmend auch außerhalb des Wohnortes. Fast allen ist ein tiefes Anliegen, in der heimatlichen Erde bestattet zu werden. Wir erledigen alle notwendigen Formalitäten im In- und Ausland. Die vielfältigen nationalen und internationalen gesetzlichen Bestimmungen werden dabei strengstens beachtet, inklusive aller erforderlichen Formalitäten mit den zuständigen Behörden und Konsulaten. Wir sorgen mit Personal und modernen Überführungsfahrzeugen für eine rasche Rückführung in die Heimat.</p>
              <DetailsBtn link="https://www.weltweite-ueberfuehrungen.de/" btnName="Unsere ausführliche Webseite" target="_blank"/>
          </article>
        </div>
      </div>
    </div>
  );
}
