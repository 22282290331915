export const contentData = {
  h3_1: "Komplettangebot Anonyme Bestattung",
  p1: `Feuerbestattung mit anonymer Beisetzung – Diskrete und einfühlsame Abschiednahme. Erfahren Sie mit uns eine sensible Möglichkeit des Abschieds, bei der die Feuerbestattung mit einer anonymen Beisetzung kombiniert wird. Unsere Dienstleistungen bieten Ihnen die Möglichkeit, den Verstorbenen auf eine unauffällige und respektvolle Weise zu verabschieden. Von der anonymen Kremation bis zur diskreten Beisetzung begleiten wir Sie einfühlsam in dieser sensiblen Phase. Unsere erfahrenen Berater stehen Ihnen zur Seite, um alle organisatorischen Details zu klären und den Prozess diskret und reibungslos zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre des Respekts und der Würde. Kontaktieren Sie uns für einfühlsame Unterstützung in dieser wichtigen Zeit.`,
  h3_2: "Komplettangebot Anonyme Bestattung",
  h3_3: "Inhalt des Angebotes",
  ul1: [
    'Kiefernsarg inkl. Bespannung ',
    'Decke und Kissen',
    'Sterbehemd , Verwendung von Privatkleidung ist möglich',
    'Desinfektion',
    'Einbetten / Einkleiden',
    'Umfassende Beratung in allen Bestattungsfragen',
    'Fahrer und Beifahrer für die Überführung',
    'Überführung vom Sterbeort zum Krematorium ',
    'Benachrichtigung der Krankenkasse',
    'Abmeldung der Rentenansprüche',
    'Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen',
    'Beurkundung beim zuständigen Standesamt',
    'Einholung und Überprüfung der Todesbescheinigung',
    'Besorgen der amtsärztlichen Leichenschau',
    'Einholung aller benötigten Unterlagen für die Feuerbestattung',
    'Aufnahme von Traueranzeigen',
    'Absprache aller notwendigen Termine',
    'Vorbereitung und Organisation der Beisetzung',
    'Krematoriumsgebühren',
    'Beisetzungskosten',
    'Kosten für die amtsärtzliche Leichenschau',
  ],
};
