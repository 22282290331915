import landingImage from "../../../Assets/Bestattungskosten_landing_img.jpg";
import LandingImageHeader from "../../../Components/LandingImageHeader/LandingImageHeader";
import "./Beisetzungskosten.scss";
import SideNav from "../../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Beisetzungskosten() {
    return (
        <div className="beisetzungskosten-p">
            <Helmet>
                <title>Beisetzungskosten | Bestattungen Lichtblick</title>
                <meta name='description' content='Informieren Sie sich ber die Bestattungskosten für Urnenplätze und Grabstätten bei unseren Partnern. Jetzt informieren!' />
            </Helmet>
            <LandingImageHeader
                img={landingImage}
                alt={"Ein einsamer Baum und eine Bank vor einem Sonnenuntergang."}
                text={`Bestattungskosten für Urnenplätze und Grabstätten`}
            />
            <div className="flex-div">
                <SideNav />
                <div tabIndex={0} id="page-content" className="page-content">
                    <article>
                        <p>Da es bei einer Waldbestattung Preisunterschiede auf Grund der Ruhestätten gibt, können Sie hier die Preise von unseren Partnern erfragen.</p>
                        <p><span className="text-primary text-bold">FriedWald - Die Bestattung in der Natur</span><br />Telefon: 06155 - 848100</p>
                        <p><span className="text-primary text-bold">Ruheforst</span><br />Telefon: 06062 - 959250</p>
                        <p><span className="text-primary text-bold">Ruhestätte Natur</span><br />Telefon: 0209 - 95709410</p>
                        <p className="warnPara">
                            Nehmen Sie mit uns oder unseren Partnern Kontakt auf, um weitere Informationen über die möglichen Ruhestätten zu erfahren.
                        </p>
                    </article>
                </div>
            </div>
        </div>
    );
}
