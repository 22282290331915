import landingImage from "../../Assets/Waldbestattung_landing_img.jpg";
import pdfFile from "../../Assets/downloads/Auftrag+Waldbestattung+2022.pdf"
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Waldbestattungen.scss";
import PriceModel from "../../Components/PriceModel/PriceModel";
import SideNav from "../../Components/SideNav/SideNav";
import {packagesData} from "./content-Waldbestattungen";
import {useContext} from "react";
import {AccessibilityContext} from "../../context/accessibilityContext";
import { Helmet } from 'react-helmet-async';

export default function Waldbestattungen() {
  const { displaySize } = useContext(AccessibilityContext);

  return (

    <div className="waldbestattungen-p">
      <Helmet>
        <title>Waldbestattungen | Bestattungen Lichtblick</title>
        <meta name='description' content='Stille Seebestattungen an der Nordsee und an der Ostsee oder mit Angehörigen ab Den Haag. Jetzt informieren!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Sonne scheint durch die Bäume."}
        text={`Waldbestattung - Letzte Ruhestätte unter Bäumen`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <p>Bei einer Waldbestattung wird die Urne mit der Asche des Verstorbenen an der Wurzel eines Baums beerdigt. Die Waldbestattung bietet eine Alternative zur konventionellen Grabstätten und gewinnt in Deutschland immer mehr an Beliebtheit. Dies hat einerseits damit zu tun, dass bei einer Waldbestattung die Natur die Grabpflege für die Angehörigen übernimmt, zudem besteht auch bereits zu Lebzeiten die Möglichkeit, sich für seine Beisetzung den Baum auszusuchen. Nach der Bestattung wird der Platz für einige Jahrzente nicht mehr als Beisetzungsplatz verwendet. Ein Verlängern der Nutzungsrechte sowie eine teure und ständige Grabpflege erübrigen sich bei dieser Bestattungsform.</p>
          </article>
          {packagesData.map((el, ind) => {
            return (
                <section className="package-type" key={ind}>
                  <h3 className="t2">
                    {el.ven && !displaySize.isMobile
                        ? el.packageType + el.ven
                        : el.packageType}
                    {el.ven && !displaySize.isMobile ? <></> : <br />}
                    {el.ven && !displaySize.isMobile ? <></> : el.ven}
                  </h3>
                  <div className="package">
                    {el.packages.map((el, indIn) => {
                      return (
                          <PriceModel
                              key={indIn}
                              heading={el.packageName}
                              price={el.price}
                              services={el.services}
                              link={pdfFile}
                              isStar={el.isStar}
                              target="_blank"
                              btnName="Download Auftrag"
                          />
                      );
                    })}
                  </div>
                </section>
            );
          })}

          <article>
            <h2>Folgende Service-Leistungen sind inklusive:</h2>
            <ul>
              <li>Kiefernsarg inkl. Bespannung</li>
              <li>Decke und Kissen</li>
              <li>Sterbehemd , Verwendung von Privatkleidung ist möglich</li>
              <li>Einbetten / Einkleiden</li>
              <li>Umfassende Beratung in allen Bestattungsfragen</li>
              <li>Fahrer und Beifahrer für die Überführung</li>
              <li>Überführung vom Sterbeort zum Krematorium</li>
              <li>Benachrichtigung der Krankenkasse</li>
              <li>Abmeldung der Rentenansprüche</li>
              <li>Beantragung der Ansprüche von Sterbegeld- und Lebensversicherungen</li>
              <li>Beurkundung beim zuständigen Standesamt</li>
              <li>Besorgen der amtsärztlichen Leichenschau</li>
              <li>Aufnahme von Traueranzeigen</li>
              <li>Absprache aller notwendigen Termine</li>
              <li>Vorbereitung und Organisation der Beisetzung</li>
              <li>Krematoriumsgebühren</li>
              <li>Kosten für die amtsärtzliche Leichenschau</li>
            </ul>
          </article>

        </div>
      </div>
    </div>
  );
}
