import landingImage from "../../Assets/Haushaltsaufloesung_landing_img.jpg";
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Haushaltsauflösung.scss";
import SideNav from "../../Components/SideNav/SideNav";
import { Helmet } from 'react-helmet-async';

export default function Haushaltsaufloesung() {
  return (
    <div className="haushaltsaufloesung-p">
        <Helmet>
            <title>Haushaltsauflösung | Bestattungen Lichtblick</title>
            <meta name='description' content='Wir organisieren auf Wunsch für Sie kostengünstig und zuverlässig die Auflösung des Haushaltes. Jetzt informieren!' />
        </Helmet>
      <LandingImageHeader
        img={landingImage}
        alt={"Sonnenuntergang über dem Ozean mit Wolken und Wasser."}
        text={`Wir unterstützen Sie bei der Haushaltsauflösung im Trauerfall`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
          <article>
            <p>Oft haben Hinterbliebene nach einem Trauerfall in der Familie nicht die Kraft und Zeit, um die hinterlassene Wohnung aufzulösen und Gegenstände und Möbel zu entsorgen.<br /><br />
               Sollten Sie in diesem Zusammenhang eine Räumung vornehmen müssen, können wir Ihnen durch die Vermittlung an ein renomiertes Unternehmen, diese Aufgabe abnehmen.<br /><br />
               Auch wenn Sie nicht vor Ort sein können, dürfen Sie beruhigt sein, denn es wird alles so erledigt, wie es mit Ihnen besprochen wurde.<br /><br />
               Nach Wunsch, wird das Objekt, nach Beendigung der Arbeiten, an den Vermieter oder Hausverwalter übergeben.</p>
            <p className="text-primary text-bold">Wir organisieren das auf Wunsch für Sie kostengünstig und zuverlässig.</p>
          </article>
        </div>
      </div>
    </div>
  );
}
