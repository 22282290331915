import Erdbestattung from "../../Assets/accordion-images/Erdbestattung.jpg";
import Feuerbestattung from "../../Assets/accordion-images/Feuerbestattung.jpg";
import Seebestattung from "../../Assets/accordion-images/Seebestattung.jpg";
import IslamischeBestattung from "../../Assets/accordion-images/Islamische-Bestattung.jpg";
import FriedwaldBestattung from "../../Assets/accordion-images/FriedwaldBestattung.jpg";
import Felsbestattung from "../../Assets/accordion-images/Felsbestattung.jpg";
import Almbestattung from "../../Assets/accordion-images/Almbestattung.jpg";
import Luftbestattung from "../../Assets/accordion-images/Luftbestattung.jpg";
import Weltraumbestattung from "../../Assets/accordion-images/Weltraumbestattung.jpg";
import Diamantbestattung from "../../Assets/accordion-images/Diamantbestattung.jpg";

export const mainHeadline = 'Bestattungsarten'
export const p1Content = {
  p1_1: `Mittlerweile gibt es zahlreiche unterschiedliche Bestattungsarten. Wir haben einen guten Überblick über die Möglichkeiten und helfen Ihnen gerne bei Ihrer Entscheidung, auf welche Art ein geliebter Mensch bestattet werden soll und vor allem in der Zeit der großen Trauer getroffen werden muss. Gerne erläutern wir Ihnen die passenden Beisetzungsart zu finden und zeigen Ihnen die Möglichkeiten auf.`,
  p1_2: `Wir bieten Ihnen drei Komplettangebote, die alle Leistungen abdecken,
  die für eine Bestattung erforderlich sind. Bei uns haben Sie auch die
  Möglichkeit Seebestattungen und Waldbestattungen durchführen zulassen,
  dazu stehen Ihnen ebenfalls Komplettangebote zur Auswahl.`,
};

export const bestattungenTypeData = [
  {
    typeTitle: "Traditionelle  Bestattungen",
    data: [
      {
        title: "Erdbestattung",
        subline: "Die Erdbestattung – eine traditionelle und respektvolle Form des Abschieds.",
        img: Erdbestattung,
        alt: "Grüne Graslandschaft mit blauem Himmel im Hintergrund.",
        content: `Bestattungen Lichtblick bietet im Trauerfall angemessene Dienstleistungen für Erdbestattungen an, um Abschied auf eine würdevolle Weise zu nehmen. Von der Wahl des passenden Grabes bis zur Organisation der Trauerfeier begleiten wir Sie einfühlsam in dieser schweren Zeit. Unser erfahrenes Bestattungsteam steht Ihnen zur Seite, um alle Formalitäten zu regeln und den Prozess so reibungslos wie möglich zu gestalten. Wir legen Wert auf individuelle Wünsche und sorgen dafür, dass der Abschiedsritus den persönlichen Vorstellungen entspricht. Vertrauen Sie auf unsere Erfahrung, um einen liebevollen und harmonischen Abschied zu ermöglichen. Ihre Wünsche stehen im Mittelpunkt unseres Handelns – für eine Erdbestattung, die dem Gedenken an den Verstorbenen gerecht wird.`,
        page: "../Komplettangebote/Erdbestattungen",
      },
      {
        title: "Feuerbestattung",
        subline: "Die Feuerbestattung – eine moderne und würdevolle Alternative zur klassischen Bestattung.",
        img: Feuerbestattung,
        alt: "Drei brennende Kerzen auf dunklem Hintergrund.",
        content: `Unsere Feuerbestattungsdienstleistungen bieten individuelle Gestaltungsmöglichkeiten für einen persönlichen Abschiedsritus. Von der Auswahl des Krematoriums bis zur Ausgestaltung der Gedenkfeier unterstützt Sie Bestattungen Lichtblick bei jedem Schritt. Erfahrene Berater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so reibungslos wie möglich zu gestalten. Eine Betreuung und Rücksicht auf persönliche Wünsche schafft eine einfühlsame Atmosphäre. Vertrauen Sie auf unsere Erfahrungen, um eine Feuerbestattung zu arrangieren, die den individuellen Vorstellungen und dem Gedenken an den Verstorbenen gerecht wird. Kontaktieren Sie uns für eine professionelle Unterstützung.`,
        page: "../Komplettangebote/Feuerbestattungen",
      },
      {
        title: "Seebestattung",
        subline: "Die Seebestattung – eine einzigartige und maritime Form des Abschiednehmens.",
        img: Seebestattung,
        alt: "Spuren im Sand entlang der Küste, von der Brandung umspült.",
        content: `Erfahren Sie mit unserem Bestattungsunternehmen die einfühlsame Möglichkeit, den Verstorbenen auf ihrem letzten Weg auf dem Meer zu gedenken. Unsere Seebestattungsdienstleistungen bieten individuelle Gestaltungsmöglichkeiten für einen würdevollen Abschied. Von der Auswahl des Bestattungsortes bis zur Organisation der Zeremonie auf See begleitet Sie Bestattungen Lichtblick einfühlsam in dieser besonderen Phase. Unsere erfahrenen Berater stehen Ihnen bei allen organisatorischen Fragen zur Seite, um den Ablauf so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung und Respekt für persönliche Wünsche schaffen wir eine maritime Atmosphäre, die dem Gedenken an den Verstorbenen gerecht wird. Vertrauen Sie unserem Wissen für eine Seebestattung, die die Einzigartigkeit des Moments einfängt.`,
        page: "../Seebestattungen",
      },
      {
        title: "Islamische Bestattung",
        subline: "Die Islamische Bestattung – eine ehrwürdige Tradition im Einklang mit den Grundsätzen des Islam.",
        img: IslamischeBestattung,
        alt: "Bild eines braunen Rosenkranzes vor weißem Hintergrund.",
        content: `Wir respektieren die religiösen Riten und bieten einfühlsame Unterstützung in dieser emotionalen Zeit. Von der rituellen Waschung bis zur Beisetzung gemäß den islamischen Bräuchen begleitet Bestattungen Lichtblick Sie mit tiefem Verständnis. Unsere erfahrenen Bestattungsberater stehen Ihnen zur Seite, um alle organisatorischen Details zu klären und den Prozess entsprechend den religiösen Vorgaben zu gestalten. Mit respektvoller Sensibilität für die kulturellen Bedürfnisse schaffen wir eine Atmosphäre, die den Gedenkfeierlichkeiten gerecht wird. Wir haben die Erfahrung für eine islamische Bestattung, die den spirituellen Anforderungen entspricht.`,
      },
    ],
  },
  {
    typeTitle: `Moderne und alternative 
  Bestattungsmöglichkeiten`,
    data: [
      {
        title: "Friedwald",
        subline: "Die Friedwald Bestattung – naturnahes Gedenken im Einklang mit der Natur.",
        img: FriedwaldBestattung,
        alt: "Sonne scheint durch die Bäume.",
        content: `Entdecken Sie mit Bestattungen Lichtblick die Möglichkeit einer Friedwald-Bestattung, bei der die Verstorbenen in einem natürlichen Waldareal ihre letzte Ruhe finden. Unsere Dienstleistungen bieten individuelle Gestaltungsmöglichkeiten für eine würdevolle Abschiedszeremonie. Von der Auswahl des Baumes bis zur Organisation der Gedenkfeier begleiten wir Sie in dieser besonderen Umgebung. Unsere erfahrenen Bestattungsberater stehen Ihnen zur Seite, um alle organisatorischen Details zu klären und den Prozess so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre des Friedens und der Verbundenheit im Naturwald. Vertrauen Sie auf unsere Erfahrungen für eine Naturbestattung, die den naturnahen Gedenkansprüchen gerecht wird.`,
      },
      {
        title: "Felsbestattung",
        subline: "Die Felsbestattung – eine außergewöhnliche Form der letzten Ruhe inmitten imposanter Naturkulissen.",
        img: Felsbestattung,
        alt: " Ein ausgedehnter Ozean mit ruhigem Wasser und einem strahlend blauen Himmel.",
        content: `Erleben Sie mit uns die Einzigartigkeit einer Felsbestattung, bei der die Verstorbenen an einem markanten Felsen beigesetzt werden. Unsere Dienstleistungen ermöglichen eine individuelle und respektvolle Ausgestaltung dieser besonderen Abschiedsform. Von der Auswahl des geeigneten Felsens bis zur Organisation der Zeremonie begleiten wir Sie in dieser einzigartigen Umgebung. Unsere erfahrenes Bestatter-Team steht Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre des Friedens und der Verbundenheit. Vertrauen Sie auf unsere Erfahrung für eine Felsbestattung, die den Ansprüchen an Individualität und Naturnähe gerecht wird.`,
      },
      {
        title: "Almbestattung",
        subline: "Die Almbestattung – Harmonie zwischen Himmel und Erde. Entdecken Sie mit uns eine einzigartige Form der Bestattung inmitten idyllischer Alpenlandschaften.",
        img: Almbestattung,
        alt: "Sonnenstrahlen durchdringen grünes Grasfeld, Naturidylle.",
        content: `Unsere Dienstleistungen ermöglichen eine individuelle Gestaltung dieser besonderen Abschiedsart. Von der Auswahl der Alm bis zur Organisation der Gedenkfeier begleiten wir Sie einfühlsam in dieser naturnahen Umgebung. Unsere Bestattungsberater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so einfach wie möglich zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre des Friedens und der Verbundenheit. Vertrauen Sie auf unserer Erfahrung für eine Almbestattung, die den Ansprüchen an Naturnähe und Individualität gerecht wird.`,
      },
      {
        title: "Luftbestattung ",
        subline: "Die Luftbestattung – der Himmel als letzte Ruhestätte. Erleben Sie mit uns eine einzigartige Form der Bestattung, bei der die Asche des Verstorbenen in den Himmel entlassen wird.",
        img: Luftbestattung,
        alt: "Ein Feld voller Sonnenblumen, die in der Sonne strahlen. Ein leuchtendes Meer aus gelben Blüten.",
        content: `Bestattungen Lichtblick ermöglicht eine individuelle und würdevolle Ausgestaltung dieses besonderen Abschiedsform. Von der Auswahl des Flugzeugs bis zur Organisation der Zeremonie begleiten wir Sie eng Hand in Hand in dieser luftigen Umgebung. Unsere erfahrenen Berater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so reibungslos wie möglich zu gestalten. Durch eine sensible Betreuung schaffen wir eine Atmosphäre des Friedens und der Verbundenheit. Vertrauen Sie auf unser Wissen für eine Luftbestattung, die den Ansprüchen an Individualität und Erhabenheit gerecht wird.`,
      },
      {
        title: "Weltraumbestattung",
        subline: "Die Weltraumbestattung – eine kosmische Reise für einen einzigartigen Abschied.",
        img: Weltraumbestattung,
        alt: "Dunkler Nachthimmel mit funkelnden Sternen.",
        content: `Tauchen Sie mit uns ein in die faszinierende Welt der Weltraumbestattung, bei der die Asche des Verstorbenen in den Weltraum geschossen wird. Bestattungen Lichtblick ermöglicht eine individuelle und respektvolle Ausgestaltung dieses außergewöhnlichen Abschiedsritus. Von der Auswahl der Raumsonde bis zur Organisation der Zeremonie begleiten wir Sie einfühlsam in dieser intergalaktischen Reise. Unsere erfahrenen Bestattungsberater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre des Staunens und der Verbundenheit. Vertrauen Sie auf unserer Erfahrung für eine Weltraumbestattung, die den Ansprüchen an Einzigartigkeit und kosmische Erhabenheit gerecht wird.`,
      },
      {
        title: "Diamantbestattung",
        subline: "Die Diamantbestattung – einzigartige Erinnerungen, die für die Ewigkeit geschaffen werden.",
        img: Diamantbestattung,
        alt: "Drei Diamantsteine auf weißem Hintergrund. Glänzend und funkelnd.",
        content: `Es ist eine besondere Form der Bestattung, bei der die Asche des Verstorbenen zu einem individuellen Erinnerungsdiamanten veredelt wird. Bestattungen Lichtblick unterstützt Sie für eine persönliche und würdevolle Ausgestaltung dieser einzigartigen Abschiednahme. Von der Auswahl des Diamanten bis zur Organisation der Gedenkfeier, begleiten wir Sie einfühlsam in diesem emotionalen Prozess. Unsere erfahrenen Berater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Ablauf so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung schaffen wir eine Atmosphäre der Wertschätzung und der Verbundenheit. Vertrauen Sie auf unserer Erfahrung für eine Diamantbestattung, die den individuellen Wünschen und dem Streben nach bleibenden Erinnerungen gerecht wird.`,
      },
    ],
  },
];
