import "./SideNav.scss";
import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { navRoutesData } from "../routesDataForNav";
import { AccessibilityContext } from "../../context/accessibilityContext";
export default function SideNav() {
  const [isOpen, setIsOpen] = useState(Array(navRoutesData.length).fill(false));
  const { displaySize } = useContext(AccessibilityContext);
  const toggleAccordion = (findInd) => {
    setIsOpen(
      isOpen.map((el, valInd) => {
        return valInd === findInd ? !el : false;
      })
    );
  };
  const curLocation = useLocation().pathname;

  useEffect(() => {
    //to open accordion initially
    const index = navRoutesData.findIndex(
      (item) => item.path === `/${curLocation.split("/")[1]}`
    );
    toggleAccordion(index);
  }, []);

  function isPathPartSame(linkPath, position = 1) {
    if (`/${curLocation.split("/")[position]}` === linkPath) {
      return true;
    } else {
      return false;
    }
  }
  return displaySize.isDesktop ? (
    <nav className="sideNav-c">
      {navRoutesData.map((el, ind) => {
        return (
          <div className="accordion" key={ind}>
            <div className="mainNav">
              <div
                onClick={() => {
                  toggleAccordion(ind);
                }}
                className="icon"
              >
                <motion.button
                  animate={{ rotate: isOpen[ind] ? 45 : 0 }}
                  transition={{ duration: 0.3 }}
                  className={"circle" + (isOpen[ind] ? " borderGray" : "")}
                >
                  <span
                    className={"hor-line" + (isOpen[ind] ? " colorGray" : "")}
                  ></span>
                  <span
                    className={"ver-line" + (isOpen[ind] ? " colorGray" : "")}
                  ></span>
                </motion.button>
              </div>
              <Link
                className={
                  "accordion-title" +
                  (isPathPartSame(el.path) ? " active" : "") +
                  (el.subPath && isPathPartSame(el.path) ? " hasMoreLinks" : "")
                }
                to={el.path}
                title={el.pathName}
              >
                {el.pathName}
              </Link>
            </div>
            {el.subPath ? (
              <div>
                <motion.div
                  className="accordion-content"
                  initial={{ height: 0 }}
                  animate={{ height: isOpen[ind] ? "auto" : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {el.subPath.map((elIn, indIn) => {
                    return (
                      <Link
                        key={indIn}
                        className={
                          "accordion-subTitle" +
                          (isPathPartSame("/" + elIn, 2)
                            ? " active"
                            : isPathPartSame(el.path, 1)
                            ? " partOfactive"
                            : "")
                        }
                        to={el.path + "/" + elIn}
                        title={elIn}
                      >
                        {elIn}
                      </Link>
                    );
                  })}
                </motion.div>
              </div>
            ) : null}
          </div>
        );
      })}
    </nav>
  ) : (
    <></>
  );
}
