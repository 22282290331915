import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import Feuerbestattungen from "./Feuerbestattungen/Feuerbestattungen";
import Erdbestattungen from "./Erdbestattungen/Erdbestattungen";
import AnonymeBestattung from "./Anonyme-Bestattung/Anonyme-Bestattung";
import Komplettangebote from "./Komplettangebote";

const RenderKomplettangeboteRoutes = () => {
  const routesData = useMemo(
    () => [
      {
        path: "/",
        element: <Komplettangebote />,
      },
      {
        path: "/Feuerbestattungen",
        element: <Feuerbestattungen />,
      },
      {
        path: "/Erdbestattungen",
        element: <Erdbestattungen />,
      },
      {
          path: "/Anonyme-Bestattung",
          element: <AnonymeBestattung />
      }
    ],
    []
  );

  return (
    <Routes>
      {routesData.map((el, ind) => {
        return (
          <Route key={`route${ind}`} path={el.path} element={el.element} />
        );
      })}
    </Routes>
  );
};

export default RenderKomplettangeboteRoutes;
