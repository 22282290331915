export const packagesData = [
    {
        packageType: "Bestattungen - Komplettangebote",
        packages: [
            {
                packageName: "Feuerbestattung mit Trauerfeier",
                price: "999",
                services: "zzgl. städt. Kosten",
                link: "/Komplettangebote/Feuerbestattungen",
                isStar: false,
            },
            {
                packageName: "Feuerbestattung mit anonymer Beisetzung",
                price: "1.150",
                services: "inkl. Beisetzungskosten und Krematoriumsgebühren",
                link: "/Komplettangebote/Anonyme-Bestattung",
                isStar: false,
            },
            {
                packageName: "Erdbestattung mit Trauerfeier",
                price: "1.800",
                services: "zzgl. städt. Kosten",
                link: "/Komplettangebote/Erdbestattungen",
                isStar: false,
            },
        ],
    },
];