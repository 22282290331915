import React, { useState, useEffect } from "react";
import DetailsBtn from "../Btn/DetailsBtn";
import { motion } from "framer-motion";
import "./ImageAccordion.scss";
import { useContext } from "react";
import { AccessibilityContext } from "../../context/accessibilityContext";

export default function ImageAccordion({
  title,
  img,
  alt,
  subline,
  content,
  page,
  isOpen,
  onClick,
}) {
  const [isAnimating, setIsAnimating] = useState(false);
  const { displaySize } = useContext(AccessibilityContext);

  useEffect(() => {
    setIsAnimating(false);
  }, [isOpen]);

  const handleClick = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      onClick();
    }
  };

  return (
    <article className="imageAccordion-c">
      <div
        className={"outDiv flex-column" + (isOpen ? " gap-20" : "")}
        onClick={handleClick}
      >
        <div className="flex-row accordion-title">
          <h4>{title}</h4>
          <button className="circle">
            <span className="hor-line"></span>
            <motion.span
              initial={{ height: 0 }}
              animate={{ height: isOpen ? 0 : 12 }}
              transition={{ duration: 0.3 }}
              className="ver-line"
            ></motion.span>
          </button>
        </div>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: isOpen ? "auto" : 0 }}
          transition={{ duration: 0.3 }}
          className={
            "acc-content gap-20" +
            (displaySize.width < 600 ? " flex-column" : " flex-row")
          }
        >
          {displaySize.width < 600 ? (
            <>
              <div className="img-content-div">
                <img className="acc-img" src={img} alt={alt} />
                <h3>{subline}</h3>
                <p></p>
                <p>{content}</p>
              </div>
              { page ? (
                  <>
                    <DetailsBtn link={page} />
                  </>
                ) : (
                    <>
                    </>
                )
              }
            </>
          ) : (
            <>
              <div
                className={
                  "img-content-div" +
                  (displaySize.isMobile ? "" : " flex-column")
                }
              >
                <h3>{subline}</h3>
                <p>{content}</p>

                { page ? (
                  <>
                    <DetailsBtn link={page} />
                  </>
                  ) : (
                      <>
                      </>
                  )
                }

              </div>
              <img className="acc-img" src={img} alt={alt} />
            </>
          )}
        </motion.div>
      </div>
    </article>
  );
}
