import landingImage from "../../Assets/Seebestattung_landing_img.jpg";
import pdfFileNordsee from "../../Assets/downloads/Nordsee+Auftrag+Still+22.pdf"
import pdfFileOstsee from "../../Assets/downloads/Auftrag-Still-Ostsee.pdf"
import pdfFileSeeFamilie from "../../Assets/downloads/Auftrag-Seebestattung-mit-Familie.pdf"
import LandingImageHeader from "../../Components/LandingImageHeader/LandingImageHeader";
import "./Seebestattungen.scss";
import SideNav from "../../Components/SideNav/SideNav";
import {
    packagesNordsee,
    packagesOstsee,
    packagesSeeFamilie
} from "./content-Seebestattungen";
import PriceModel from "../../Components/PriceModel/PriceModel";
import {useContext} from "react";
import {AccessibilityContext} from "../../context/accessibilityContext";
import { Helmet } from 'react-helmet-async';

export default function Seebestattungen() {
    const { displaySize } = useContext(AccessibilityContext);
  return (
    <div className="seebestattungen-p">
        <Helmet>
            <title>Seebestattungen | Bestattungen Lichtblick</title>
            <meta name='description' content='Stille Seebestattungen an der Nordsee und an der Ostsee oder mit Angehörigen ab Den Haag. Jetzt informieren!' />
        </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Spuren im Sand entlang der Küste, von der Brandung umspült."}
        text={`Die Seebestattung – eine einzigartige und maritime Form des Abschiednehmens.`}
      />
      <div className="flex-div">
        <SideNav />
        <div tabIndex={0} id="page-content" className="page-content">
            <article>
                <p>Erfahren Sie mit unserem Bestattungsunternehmen die einfühlsame Möglichkeit, den Verstorbenen auf ihrem letzten Weg auf dem Meer zu gedenken. Unsere Seebestattungsdienstleistungen bieten individuelle Gestaltungsmöglichkeiten für einen würdevollen Abschied. Von der Auswahl des Bestattungsortes bis zur Organisation der Zeremonie auf See begleitet Sie Bestattungen Lichtblick einfühlsam in dieser besonderen Phase. Unsere erfahrenen Berater stehen Ihnen bei allen organisatorischen Fragen zur Seite, um den Ablauf so reibungslos wie möglich zu gestalten. Mit sensibler Betreuung und Respekt für persönliche Wünsche schaffen wir eine maritime Atmosphäre, die dem Gedenken an den Verstorbenen gerecht wird. Vertrauen Sie unserem Wissen für eine Seebestattung, die die Einzigartigkeit des Moments einfängt.</p>
            </article>

            {packagesNordsee.map((el, ind) => {
                return (
                    <section className="package-type" key={ind}>
                        <h3 className="t2">
                            {el.ven && !displaySize.isMobile
                                ? el.packageType + el.ven
                                : el.packageType}
                            {el.ven && !displaySize.isMobile ? <></> : <br />}
                            {el.ven && !displaySize.isMobile ? <></> : el.ven}
                        </h3>
                        <div className="package">
                            {el.packages.map((el, indIn) => {
                                return (
                                    <PriceModel
                                        key={indIn}
                                        heading={el.packageName}
                                        price={el.price}
                                        services={el.services}
                                        link={pdfFileNordsee}
                                        isStar={el.isStar}
                                        target="_blank"
                                        btnName="Download Auftrag"
                                    />
                                );
                            })}
                        </div>
                    </section>
                );
            })}

            <article>
                <h2>Folgende Service-Leistungen sind inklusive:</h2>
                <ul>
                    <li>Beisetzungskosten sind im Paket enthalten</li>
                    <li>Kiefernsarg mit Bespannung</li>
                    <li>Decke und Kissen</li>
                    <li>Sterbehemd</li>
                    <li>Desinfektion</li>
                    <li>Einbetten / Einkleiden</li>
                    <li>Fahrer / Beifahrer</li>
                    <li>Überführung vom Sterbeort zum Krematorium</li>
                    <li>Erledigung aller Formalitäten</li>
                    <li>Abrechnung mit Kassen und Versicherungen</li>
                    <li>Besorgung der Sterbeurkunde</li>
                    <li>Vorbereitung und Organisation der Beisetzung</li>
                    <li>Krematoriumsgebühren</li>
                    <li>Überführung der Urne zum Beisetzungsort</li>
                    <li>Gebühren von der Reederei für die Beisetzung der Urne in der Nordsee</li>
                    <li>Amtsärztliche Untersuchung</li>
                    <li>Auf Wunsch erhalten Sie eine Seekarte auf der Sie sehen können wo Ihr Angehöriger beigesetzt wurde</li>
                </ul>
            </article>

            {packagesOstsee.map((el, ind) => {
                return (
                    <section className="package-type" key={ind}>
                        <h3 className="t2">
                            {el.ven && !displaySize.isMobile
                                ? el.packageType + el.ven
                                : el.packageType}
                            {el.ven && !displaySize.isMobile ? <></> : <br />}
                            {el.ven && !displaySize.isMobile ? <></> : el.ven}
                        </h3>
                        <div className="package">
                            {el.packages.map((el, indIn) => {
                                return (
                                    <PriceModel
                                        key={indIn}
                                        heading={el.packageName}
                                        price={el.price}
                                        services={el.services}
                                        link={pdfFileOstsee}
                                        isStar={el.isStar}
                                        target="_blank"
                                        btnName="Download Auftrag"
                                    />
                                );
                            })}
                        </div>
                    </section>
                );
            })}

            <article>
                <h2>Folgende Service-Leistungen sind inklusive:</h2>
                <ul>
                    <li>Beisetzungskosten sind im Paket enthalten</li>
                    <li>Kiefernsarg mit Bespannung</li>
                    <li>Decke und Kissen</li>
                    <li>Sterbehemd</li>
                    <li>Desinfektion</li>
                    <li>Einbetten / Einkleiden</li>
                    <li>Fahrer / Beifahrer</li>
                    <li>Überführung vom Sterbeort zum Krematorium</li>
                    <li>Erledigung aller Formalitäten</li>
                    <li>Abrechnung mit Kassen und Versicherungen</li>
                    <li>Besorgung der Sterbeurkunde</li>
                    <li>Vorbereitung und Organisation der Beisetzung</li>
                    <li>Krematoriumsgebühren</li>
                    <li>Überführung der Urne zum Beisetzungsort</li>
                    <li>Gebühren von der Reederei für die Beisetzung der Urne in der Ostsee</li>
                    <li>Amtsärztliche Untersuchung</li>
                    <li>Auf Wunsch erhalten Sie eine Seekarte auf der Sie sehen können wo Ihr Angehöriger beigesetzt wurde</li>
                </ul>
            </article>

            {packagesSeeFamilie.map((el, ind) => {
                return (
                    <section className="package-type" key={ind}>
                        <h3 className="t2">
                            {el.ven && !displaySize.isMobile
                                ? el.packageType + el.ven
                                : el.packageType}
                            {el.ven && !displaySize.isMobile ? <></> : <br />}
                            {el.ven && !displaySize.isMobile ? <></> : el.ven}
                        </h3>
                        <div className="package">
                            {el.packages.map((el, indIn) => {
                                return (
                                    <PriceModel
                                        key={indIn}
                                        heading={el.packageName}
                                        price={el.price}
                                        services={el.services}
                                        link={pdfFileSeeFamilie}
                                        isStar={el.isStar}
                                        target="_blank"
                                        btnName="Download Auftrag"
                                    />
                                );
                            })}
                        </div>
                    </section>
                );
            })}
            <article>
                <h2>Folgende Service-Leistungen sind inklusive:</h2>
                <ul>
                    <li>Beisetzungskosten sind im Paket enthalten</li>
                    <li>Kiefernsarg mit Bespannung</li>
                    <li>Decke und Kissen</li>
                    <li>Sterbehemd</li>
                    <li>Desinfektion</li>
                    <li>Einbetten / Einkleiden</li>
                    <li>Fahrer / Beifahrer</li>
                    <li>Überführung vom Sterbeort zum Krematorium</li>
                    <li>Erledigung aller Formalitäten</li>
                    <li>Abrechnung mit Kassen und Versicherungen</li>
                    <li>Besorgung der Sterbeurkunde</li>
                    <li>Vorbereitung und Organisation der Beisetzung</li>
                    <li>Krematoriumsgebühren</li>
                    <li>Gebühren von der Reederei für die Beisetzung der Urne in der Nordsee</li>
                    <li>Amtsärztliche Untersuchung</li>
                    <li>Sie erhalten  eine Seekarte auf der Sie sehen können wo Ihr Angehöriger beigesetzt wurde</li>
                </ul>
            </article>

            <article className="warnPara">
                <p>
                    Falls Sie bei der Beisetzung der Urne anwesend sein wollen, oder einen anderen Beisetzungshafen wünschen, nehmen Sie mit uns Kontakt auf da die Reedereien verschiedene Preise haben.
                </p>
            </article>
        </div>
      </div>
    </div>
  );
}
